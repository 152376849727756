/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component }  from "react";
import axios from 'axios';

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";


class SectionContact extends Component {
  // initialize our state
  state = {
      data: [],
      name: null,
      email: null,
      phone: null,
      subject: null,
      message: null,
      intervalIsSet: false,
   
  };

  // when component mounts, first thing it does is fetch all existing data in our db
  // then we incorporate a polling logic so that we can easily see if our db has
  // changed and implement those changes into our UI
  componentDidMount() {
     
  } 

  // never let a process live forever
  // always kill a process everytime we are done using it
 componentWillUnmount() {
    /*  if (this.state.intervalIsSet) {
          clearInterval(this.state.intervalIsSet);
          this.setState({ intervalIsSet: null });
      }
      */
  } 
  //uIVx7NH]zkkT ssh key

  // our put method that uses our backend api
  // to create new query into our data base
  putDataToDB = (name,email,phone,subject,message) => {
    var bodyFormData = [{
      name:name,
      email:email,
      phone:phone,
      subject:subject,
      message: message
    }];
    // alert(message);
    //   // axios.post('http://jatversion.janubiasiantraders.com/app', {
    //  axios.post('https://guarded-sierra-71715.herokuapp.com/putData',);

      axios.post('https://guarded-sierra-71715.herokuapp.com/putData', bodyFormData)
        .then(res => alert('Your Message send successfully.'))
        .catch(err => alert('Something went wrong. Please try again later.'));
      //alert('done');

      // var bodyFormData = {
      //   name:name,
      //   email:email,
      //   phone:phone,
      //   subject:subject,
      //   message: message
      // };
      // axios({
      //   method: 'post',
      //   url: 'https://guarded-sierra-71715.herokuapp.com/putData',
      //   body: bodyFormData,
      //   headers: {'Content-Type': 'application/x-www-form-urlencoded' }
      //   })
      //   .then(function (response) {

      //     alert('Your Message send successfully.');
      //       //handle success
      //     //  console.log(response);
      //   })
      //   .catch(function (response) {
      //     alert('Something went wrong. Please try again later.');
      //       //handle error
      //       //console.log(response);
      //   });
        

  };

 
  // here is our UI
  // it is easy to understand their functions when you
  // see them render into our screen
  render() {

      return (
        <div className="section section-navbars">
        <Container id="menu-dropdown">
          <div className="title">
            <h3>CONTACT US</h3>
          </div>
          <br />
          <Row>
            <Col md="6">
               <p>
                  <b>GET IN TOUCH</b><hr /><br />Janubi Asian traders is a well known trader Company. Where you can get all export Services under one roof to enhance your business. We will do everything for you, so you do not have to worry about your business and can focus on the profitable activities of your business!!!!<br />
                  <hr />
                  <Row>
                  <Col md="4">
                  <center>
                  <div className="info">
                  <div className="icon icon-info">
                  <i className="nc-icon nc-mobile" />
                  </div>
                      <div className="description">
                        <h4 className="info-title">Phone</h4>
                        <a href="tel:+923005810088">+923005810088</a>
                      </div>
                  </div>
                  </center>
                  </Col>
                  <Col md="4">
                  <center>
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-pin-3" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Address</h4>
                    <span>Lahore, Pakistan.</span>
                  </div>
                </div>
                </center>
                </Col>
                <Col md="4">
                  <center>
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-email-85" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Email</h4>
                    <span><a
                  href="mailto:janubiasiantraders@gmail.com">
                    janubiasiantraders@gmail.com</a></span>
                  </div>
                </div>
                </center>  
                </Col>
                </Row>
                   </p>
                   
            </Col>
            <Col md="6">
              <center><p><b>Message us</b></p></center>
              <hr />
              <br />
              <center>
                <Col md="8">
              <InputGroup className="has-primary">
                <Input placeholder="Name" type="text" name="name" onChange={(e) => this.setState({ name: e.target.value })} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i aria-hidden={true} className="fa fa-user" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <br />

              <InputGroup className="has-primary">
                <Input placeholder="Email" type="text" name="email" onChange={(e) => this.setState({ email: e.target.value })} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i aria-hidden={true} className="fa fa-cc" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <br />

              <InputGroup className="has-primary">
                <Input placeholder="Phone" type="text" name="phone" onChange={(e) => this.setState({ phone: e.target.value })} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i aria-hidden={true} className="fa fa-phone" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <br />

              <InputGroup className="has-primary">
                <Input placeholder="Subject" type="text" name="subject" onChange={(e) => this.setState({ subject: e.target.value })} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i aria-hidden={true} className="fa fa-sticky-note" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <br />

              <InputGroup className="has-primary">
                <Input placeholder="Message" type="text" name="message" onChange={(e) => this.setState({ message: e.target.value })} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i aria-hidden={true} className="fa fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              </Col>

               <br />
              <Button
                  className="btn-round mr-1"
                  color="info"
                  outline
                  type="button"
                  onClick={() => this.putDataToDB(this.state.name,this.state.email,this.state.phone,this.state.subject,this.state.message)}
                >
                  Submit
                </Button>

              </center>
          </Col> 
          </Row>
        </Container>
      </div>
         
  );
  }
}

export default SectionContact;
