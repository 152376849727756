/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Button , Row, Col } from "reactstrap";

// core components

function SectionContact() {
  return (
    <>
      <div className="section section-dark section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="12" md="12">
              <h3 className="title" style={{textAlign:"center"}}>24 HOURS / 7 DAYS ONLINE LIVE SUPPORT</h3>
            </Col>
            <Col lg="6" md="12">
              <h3 className="title" style={{textAlign:"center"}}>FREE CALL SUPPORT</h3>
              <p className="description" style={{textAlign:"center"}}>
              Call us and we will guide you in a proper way,<br /> to clear all your queries.
              <br />
              <br />
              <Button
                className="btn-round ml-1"
                color="success"
                href="tel:+923005810088"
                outline
              >
                Call us
              </Button>
              </p>
            </Col>
            <Col lg="6" md="12">
            <h3 className="title" style={{textAlign:"center"}}>24 / 7 LIVE CHAT</h3>
              <p className="description" style={{textAlign:"center"}}>
              Customer care center is open 24 /7 to facilitatethe<br /> clients across the world.
              <br />
              <br />
              <Button
                className="btn-round ml-1"
                color="danger"
                href="mailto:janubiasiantraders@gmail.com"
                outline
              >
                Contact us
              </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionContact;
