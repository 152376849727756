/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Button , Row, Col } from "reactstrap";

// core components

function SectionDark() {
  return (
    <>
      <div
        className="section section-image"
        style={{
          backgroundImage: "url(" + require("assets/img/success.jpg") + ")"
        }}
      ><h2 className="title" style={{textAlign:"center", color:"white"}}>Live with us for your dream's success</h2>
      </div>{" "}
    </>
  );
}

export default SectionDark;
