/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

function SectionBasmati() {
  return (
    <>
      <div className="section section-navbars">
        <Container id="menu-dropdown">
          <div className="title">
            <h3>BASMATI RICE</h3>
          </div>
          <br />
          <Row>
            <Col md="12">
               <p>
                  <b>Basmati</b> is a variety of long grain rice produce in the Land of Pakistan, notable for its fragrance and delicate, nuanced flavor. Its name means "the fragrant one" in Sanskrit, but it can also mean "the soft rice.".<br />
                  On cooking white Basmati rice gives strong aroma, sweet taste, swelling on cooking, Queen of fragrance, easily digested and softness.It is the most Famous type of Rice cultivated in Pakistan. When boiled it become extra long and its size become twice . It has fragrance of its own. Each grain remain unbroken after cooking Today these are capture nearly more than 20% of the world. Now it is available at high demand in our company to fulfill the need of the world. We should provide these without any difference in the quality.I hope once you deal with us you will become our parmanent customer. We should provide full satisfaction to you.<br />
                  You can also come for personal visit for verify our quality and we can make best relations.
                  <br />
                  <br />
                  Rice consist of the following
                  <ul>
                    <li>Calorie</li>
                    <li>Carbohydrates</li>
                    <li>Fat</li>
                    <li>Proteins</li>
                    <li>Vitamins and Minerals</li>
                  </ul>  

                  Packaging of Rice
                  <ul>
                    <li>20 kg</li>
                    <li>40 kg</li>
                    <li>50 kg</li>
                  </ul>  
                  
                   </p>
            </Col>
          </Row>
          <Row>
          <Col md="4">
              <img alt="..." style={{width:"250px",height:"250px"}} src={require("assets/img/r1.jpg")} />
            </Col>
            <Col md="4">
              <img alt="..." style={{width:"250px",height:"250px"}} src={require("assets/img/r2.jpg")} />
            </Col>
            <Col md="4">
              <img alt="..." style={{width:"250px",height:"250px"}} src={require("assets/img/r3.jpg")} /> 
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionBasmati;
