/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

function SectionNavbars() {
  let  large_text= ["large_text"];
  return (
    <>
      <div className="section section-navbars">
        <Container id="menu-dropdown">
          <div className="title">
            <h3>OUR <b>PRODUCTS</b></h3>
          </div>
          <br />
          <Row>
            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com"><img alt="rice"  className="product_image" src={require("assets/img/p_image.jpg")} />
                  <br />
                  <span><strong className={large_text}> Rice </strong></span>
              </a>    
              </center>
            </Col>

            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="salt" className="product_image" src={require("assets/img/salt.jpg")} />
                  <br />
                  <span><strong className={large_text}> Salt </strong></span>
                  </a>
              </center>
            </Col>

          </Row>
          <br />
          <Row>
            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="vegtables" className="product_image" src={require("assets/img/vegetables.jpg")} />
                  <br />
                  <span><strong className={large_text}> Vegetables </strong></span>
                </a>
              </center>
            </Col>

            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="fruit" className="product_image" src={require("assets/img/furits.jpg")} />
                  <br />
                  <span><strong className={large_text}> Fruits </strong></span>
                  </a>
              </center>
            </Col>

          </Row>
          <br />
          <Row>
            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="dry-fruits" className="product_image" src={require("assets/img/dried-fruits.jpg")} />
                  <br />
                  <span><strong className={large_text}> Dried Fruits </strong></span>
                  </a>
              </center>
            </Col>

            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="textile" className="product_image" src={require("assets/img/textile.jpg")} />
                  <br />
                  <span><strong className={large_text}> Textile </strong></span>
                  </a>
              </center>
            </Col>

          </Row>
          <br />
          <Row>
            <Col md="6">
              <center>
                <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="sports" className="product_image" src={require("assets/img/sports.jpg")} />
                  <br />
                  <span><strong className={large_text}> Sports </strong></span>
                  </a>
              </center>
            </Col>

            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="garments" className="product_image" src={require("assets/img/garments.jpg")} />
                  <br />
                  <span><strong className={large_text}> Garments </strong></span>
              </a>
              </center>
            </Col>

          </Row>
          <br />
          <Row>
            <Col md="6">
              <center>
              <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="bags" className="product_image" src={require("assets/img/bags.jpg")} />
                  <br />
                  <span><strong className={large_text}> Bags </strong></span>
                  </a>
              </center>
            </Col>

            <Col md="6">
            <center>
            <a
                  href="mailto:janubiasiantraders@gmail.com">
                  <img alt="shoes" className="product_image" src={require("assets/img/shoes.jpg")} />
                  <br />
                  <span><strong className={large_text}> Shoes </strong></span>
                  </a>
              </center>
            </Col>

          </Row>
         

            
        </Container>
      </div>
    </>
  );
}


export default SectionNavbars;
