/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

function SectionCertificate() {
  return (
    <>
      <div className="section section-navbars">
        <Container id="menu-dropdown">
          <div className="title">
            <h3>OUR <b>CERTIFICATE</b></h3>
          </div>
          <br />
          <Row>
            <Col md="12">
            <div className="title">
              <h3>Membership</h3>
            </div>
              <center>
                  <img alt="..." style={{width:'350px'}} src={require("assets/img/membership.png")} />
              </center>
            </Col>
          </Row>
          <Row>
            <Col md="12">
            <div className="title">
              <h3>Certificate</h3>
            </div>
              <center>
                  <img alt="..." style={{width:'350px'}}  src={require("assets/img/certificate1.jpg")} />
                  <br /><br />
                  <img alt="..." style={{width:'350px'}} src={require("assets/img/certificate2.jpg")} />
              </center>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
}

export default SectionCertificate;
