import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

import logo from 'assets/img/jat_logo.png';

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 5 ||
        document.body.scrollTop > 5
      ) {
        setNavbarColor("");
      } /* else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      } */
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });



  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container style={{backgroundColor: "#FFFFFF", opacity:"0.8"}}>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
          >
            <img src={logo} style={{width:"80px"}} alt="Logo" />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem >
              <NavLink
                href="/index"
                style={{color:"black"}}
              >
               Home
              </NavLink>
            </NavItem>
            <NavItem >
              <NavLink
                href="/product"
                style={{color:"black"}}
              >
               Products
              </NavLink>
            </NavItem>
          {/*  <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="black"
                        data-toggle="dropdown"
                        href="/product"
                        id="dropdownMenuButton"
                        nav
                        role="button"
                        style={{color:"black"}}
                      >
                        Product
                      </DropdownToggle>
                      <DropdownMenu
                        aria-labelledby="dropdownMenuButton"
                        className="dropdown-info"
                      >
                        <DropdownItem
                          href="/product"
                        >
                          All Products
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          href="/basmati"
                        >
                          Basmati
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          href="/rice" 
                        >
                          Rice
                        </DropdownItem>
                      </DropdownMenu>
          </UncontrolledDropdown> */}
            <NavItem>
              <NavLink
                href="/certificate" style={{color:"black"}}
              >
               Certificate
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/about"  style={{color:"black"}}
              >
               About us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/contact" style={{color:"black"}}
              >
               Contact us
              </NavLink>
            </NavItem>

             {/* <NavItem>
              <NavLink
                data-placement="bottom"
                href="#"
                target="_blank"
                title="Follow us on Twitter" style={{color:"black"}}
              >
                <i className="fa fa-twitter" />
                <p className="d-lg-none">Twitter</p>
              </NavLink>
             </NavItem>*/}

              <NavItem>
               <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/Janubi-Asian-Trader-104311597638134/"
                target="_blank"
                title="Like us on Facebook" style={{color:"black"}}
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
              </NavItem>

            {/*<NavItem>
              <NavLink
                data-placement="bottom"
                href="#"
                target="_blank"
                title="Follow us on Instagram" style={{color:"black"}}
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem> */}
      
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
