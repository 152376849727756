/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

function SectionBasmati() {
  return (
    <>
      <div className="section section-navbars">
        <Container id="menu-dropdown">
          <div className="title">
            <h3>RICE</h3>
          </div>
          <br />
          <Row>
            <Col md="6">
               <p>
                  <b>Janubi Asian Traders</b> provide new, better, faster and less expensive product and services with more choice and option at the same time expecting reduced social and environmental impacts. We are dealing with all types of rice.
                  <br />
                  <br />
                  Rice consist of the following
                  <br />
                  <ul>
                    <li>Calorie</li>
                    <li>Carbohydrates</li>
                    <li>Fat</li>
                    <li>Proteins</li>
                    <li>Vitamins and Minerals</li>
                  </ul>  
                  <br />
                  Benefits of Rice
                  <br />
                  <ul>
                    <li>Great source of Energy</li>
                    <li>Prevent Obesity</li>
                    <li>Gluten-free</li>
                    <li>Controls Blood Pressure</li>
                    <li>Prevent from Cancer</li>
                    <li>Diuretic and Digestive Qualities</li>
                    <li>Improve Metabolism</li>
                    <li>Boosts Cardiovascular Health</li>
                    <li>High Nutritional Value</li>
                    <li>Suitable for Diabetics</li>
                    <li>Prevent Hemorrhoids</li>
                    <li>Controlling Cholesterol and Reduce Heart Disease Risk</li>
                    <li>Overcoming Constipation</li>
                    <li>Help maintain Ideal Weight</li>
                  </ul>  
                  
                   </p>
            </Col>
            <Col md="6">
              <center><img alt="..." style={{width:"576px"}} src={require("assets/img/r4.jpg")} /></center>
              <center><img alt="..." style={{width:"576px"}} src={require("assets/img/r5.jpg")} /></center>
          </Col> 
          </Row>
          <br /><br />
          <Row>
          <Col md="12">
              <center><img alt="..." style={{width:"576px"}} src={require("assets/img/rice_end.jpg")} /></center>
          </Col> 
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionBasmati;
