/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Button , Row, Col } from "reactstrap";

// core components

function SectionProductBlock() {
  return (
    <>
      <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Why choose us?</h2>
                <Button
                  className="btn-round btn-danger"
                  color="info"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Our Product
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">High Quality Products</h4>
                    <p className="description">
                    High quality products are specifically designed and engineered to work fast, efficiently and reliably.
                    </p>
          
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-success">
                    <i className="nc-icon nc-money-coins" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Affordable Prices</h4>
                    <p>
                    You find our rates very competitive and reasonable. Find out more!
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-warning">
                    <i className="nc-icon nc-chat-33" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Customer Support</h4>
                    <p>
                    Find a polite and well-spoken customer care employee guiding you
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-watch-time" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">On Time</h4>
                    <p className="description">
                    Complete every project on time and handover to clients successfully.
                    </p>
          
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-success">
                    <i className="nc-icon nc-user-run" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Successful Team</h4>
                    <p>
                    Our team will not set for anything less than 100% customer satisfaction. We are the best.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-warning">
                    <i className="nc-icon nc-spaceship" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Advanced Technology</h4>
                    <p>
                    We use new technologies in all of our products. Our company is up to date with the latest technology.
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>

          </Container>
        </div>

        
<Container>
         <div
        className="section text text-center"
        style={{
          backgroundImage: "url(" + require("assets/img/ourprocess.png") + ")",
          backgroundPosition: 'center center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      >
             <Row>
                <Col xs="3">
                <i class="fa fa-hand-o-up fa-2"></i>
                <div>CHOOSE <b>ORDER</b> Select your order.</div>
                </Col>
                <Col xs="3">
                <i class="fa fa-download fa-2"></i>
                <div><b>PAID</b> Pay for your Order.</div>
                </Col>
                <Col xs="3">
                <i class="fa fa-desktop fa-2"></i>
                <div><b>READY</b> We ready your order.</div>
                </Col>
                <Col md="3">
                <i class="nc-icon nc-spaceship fa-2"></i>
                <div><b>DELIVER</b> We deliver your order.</div>
                </Col>
                
            </Row> 
          
      </div>
    </Container>

    </>
  );
}

export default SectionProductBlock;
