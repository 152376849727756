/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

function SectionNavbars() {
  return (
    <>
      <div className="section section-navbars">
        <Container id="menu-dropdown">
          <div className="title">
            <h3>WHO <b>WE</b> ARE</h3>
          </div>
          <br />
          <Row>
            <Col md="6">
              <p>
               
              Janubi Asian Traders provides reliable and affordable services to customers across the World. Dealing with our company you can improve competitiveness profitability and sustainbility.We work for clients to ensure that they receive relevant communications.
              <br /><br /><h4><b>Live with us for your dreams success</b></h4>
              </p> 
            </Col>
            <Col md="6">
              <center>
                  <img alt="..." style={{width:'350px'}} src={require("assets/img/about.jpg")} />
              </center>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionNavbars;
